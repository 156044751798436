import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import styled from 'styled-components'
import COLORS from '../utils/colors'
import '../styles/contact.scss'

const ContactSection = styled.div`
    margin-top: 72px;

    .contact-img{
        max-width:466px;
        margin:auto;
    }

    .contact-display{
      
        margin: -72px auto 0;
     
    }

    .mail, .para-rso{
        text-align: center;
        
    }

    .mail{

        position: relative;
        background-color: ${COLORS.primary};
        padding: 48px 0;
        clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
        margin-top: 48px;

        p,span,i{
            color: ${COLORS.secundary};
        }
    }

    i{
        display: block;
        margin-bottom: 24px;
    }

    span.mini{
        display: block;
        font-size: 0.8rem;
        text-align: center;
    }

    .para-rso{
        margin-top: 48px;
    }

    .para-mail{
        font-size:2rem;
    }
`

const contactImage = graphql`{
    contact:file(relativePath:{eq:"contact.png"}){
      childImageSharp{
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `

const ContactPage = () => {
    const data = useStaticQuery(contactImage)
    return (
        <Layout>
            <SEO title="Un p'tit mail et c'est parti !"/>
            <h1>Informations de contact</h1>
            <h3 className="sous-titre">Vous avez un projet de communication ou souhaitez un devis ? Discutons-en !</h3>

            <ContactSection>
                {/*<p>Envoyez-moi un e-mail en précisant votre demande et vos coordonnées, je vous recontacterai dans les plus brefs délais.</p>*/}
                <Image className="contact-img"
                    fluid={data.contact.childImageSharp.fluid} 
                    alt="Contactez-moi"
                    imgStyle= { {objectFit:'contain'} } 
                />
                    
                    <div className="contact-display">
                        
                        <div className="mail">
                            <p className="para-mail"><i class="fas fa-envelope"></i>marylise.barthelemy@gmail.com</p>
                            <span className="mini">* premier contact par mail.</span>
                            
                        </div>
                    </div>
                <p className="para-rso">Retrouvez-moi sur facebook et linked-in !</p>
            </ContactSection>
           
        </Layout>
    );
};

export default ContactPage;